.contact-title {
    color: #E2E8F0;
    text-align: center;
    margin-bottom: 2rem;
}

.contact-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    background-color: rgba(10, 25, 47, 0.9);
    border-radius: 8px;
}

.contact-form {
    width: 60%;
    display: flex;
    flex-direction: column;
}

.input-container {
    margin-bottom: 1rem;
}

.input-container label {
    color: #E2E8F0;
    margin-bottom: 0.5rem;
    display: block;
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 10px 15px;
    margin: 5px 0;
    border: 1px solid #A0AEC0;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.1);
    color: #E2E8F0;
    font-size: 0.9rem;
}

.submit-btn {
    align-self: center;
    padding: 10px 20px;
    color: #E2E8F0;
    background-color: #2D3748; /* Space gray */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-btn:hover {
    background-color: #4A5568;
}
