a {
    text-decoration: none;
    color: black;
}

.project-container {
    background-color: #020916;
    padding: 35px 0;
    position: relative;
    overflow: hidden;
}

.project-title {
    color: #E2E8F0;
    text-align: center;
    margin-bottom: 25px;
    font-size: 2.2rem;
    text-shadow: 1.5px 1.5px 3px rgba(0, 0, 0, 0.5);
}

.project-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 25px;
}

/* Adjusted card */
.space-card {
    background: #121212;
    color: #E2E8F0;
    border: 1px solid #444;
    transition: transform 0.3s, box-shadow 0.3s;
    max-width:20rem;
}

.space-card:hover {
    transform: translateY(-7px);
    box-shadow: 0 7px 14px rgba(0, 0, 0, 0.5);
}

.space-card .card-title {
    font-weight: bold;
    margin-top: 7px;
    font-size: 1.3rem;
}

.space-card .card-text {
    font-size: 1rem;
}

.custom-btn {
    margin: 10px;
}